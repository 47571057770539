<template>
  <div class="container">
    <div class="toolbar">
      <el-radio-group v-model="pregnancytypecode" @change="typeCodeChange">
        <el-radio :label="'01'">孕早期</el-radio>
        <el-radio :label="'02'">孕中期</el-radio>
        <el-radio :label="'03'">孕晚期</el-radio>
        <el-radio :label="'04'">产后三天</el-radio>
        <el-radio :label="'05'">产后四十二天</el-radio>
        <el-radio :label="'06'">孕早中期</el-radio>
        <el-radio :label="'07'">孕中晚期</el-radio>
        <el-radio :label="'08'">产后一年内</el-radio>
      </el-radio-group>
    </div>
    <el-table
      :data="tableData"
      border
      v-loading="loading"
      size="small"
      style="width: 100%"
    >
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="scoremin" label="最小分值" width="100">
        <template slot-scope="scope">
          <el-input
            size="medium"
            v-model="scope.row.scoremin"
            placeholder="请输入最小分值"
            type="number"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="最大分值" width="100">
        <template slot-scope="scope">
          <el-input
            size="medium"
            v-model="scope.row.scoremax"
            placeholder="请输入最大分值"
            type="number"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="符合该分数评估" width="220">
        <template slot-scope="scope">
          <el-input
            size="medium"
            v-model="scope.row.result"
            placeholder="请输入符合该分数评估的描述"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="建议" min-width="220">
        <template slot-scope="scope">
          <el-input
            size="medium"
            v-model="scope.row.proposal"
            placeholder="请输入符合该分数评估的建议"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="onSaveQuota(scope.row)"
            >保存</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="totalData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";

export default {
  name: "EditQuota",
  props: {
    editQuotaDetail: Object,
    closeAndRefresh: Function,
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 15,
      totalData: 0,
      tableData: [],
      loading: false,
      pregnancytypecode: "01",
    };
  },
  created() {
    this.initList();
  },
  methods: {
    initList() {
      this.loading = true;
      api
        .getAssessQuotaDetailPageList({
          quotacode: this.editQuotaDetail.quotacode,
          page: this.pageIndex,
          pageSize: this.pageSize,
          pregnancytypecode: this.pregnancytypecode,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.totalData = res.data.data.totalRecords;
            this.tableData = res.data.data.list;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    onSaveQuota(val) {
      api
        .updateAssessQuotaDetail({
          quotadetailid: val.quotadetailid,
          quotacode: val.quotacode,
          scoremin: val.scoremin,
          scoremax: val.scoremax,
          proposal: val.proposal,
          result: val.result,
          pregnancytypecode: val.pregnancytypecode,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.$message({
              showClose: true,
              message: "保存成功",
              type: "success",
            });
            this.initList();
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    typeCodeChange(val) {
      this.pregnancytypecode = val;
      this.initList();
    },
  },
};
</script>

<style scoped>
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.toolbar {
  height: 50px;
}
</style>