<template>
  <div class="container">
    <el-table :data="tableData" border size="small" style="width: 100%">
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="typename" label="测评项" width="250">
      </el-table-column>
      <el-table-column prop="quotacode" label="评估编码" width="150">
      </el-table-column>
      <el-table-column prop="quotaname" label="评估内容" width="250">
      </el-table-column>
      <el-table-column prop="quotatemplate" label="评估模型" min-width="180">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <!--                    <el-button @click="viewDetails(scope.row)" type="text" size="small">查看详情</el-button>-->
          <el-button @click="editQuota(scope.row)" type="text" size="small"
            >编辑配置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 30]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </div>
    <el-dialog
      append-to-body
      title="用户信息"
      :visible.sync="viewDetailDialog"
      width="700px"
    >
    </el-dialog>

    <el-dialog
      append-to-body
      title="编辑配置"
      :visible.sync="editQuotaDialog"
      v-if="editQuotaDialog"
      width="900px"
    >
      <EditQuota
        :editQuotaDetail="editQuotaDetailObj"
        :closeAndRefresh="closeAndRefresh"
      ></EditQuota>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api";
import EditQuota from "../../components/EditQuota";

export default {
  components: { EditQuota },
  data() {
    return {
      pageIndex: 1,
      pageSize: 15,
      totalData: 0,
      tableData: [],
      viewDetailDialog: false,
      viewDetailObj: null,
      loginData: null,
      editQuotaDialog: false,
      editQuotaDetailObj: null,
    };
  },
  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.initList();
  },
  methods: {
    initList() {
      api
        .getAssessQuotaPageList({
          categorycode: this.loginData.categorycode,
          userlevel: this.loginData.userlevel,
          page: this.pageIndex,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.totalData = res.data.data.totalRecords;
            this.tableData = res.data.data.list;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    viewDetails(row) {
      this.viewDetailObj = row;
      this.viewDetailDialog = true;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initList();
    },
    editQuota(val) {
      console.log(val);
      this.editQuotaDialog = true;
      this.editQuotaDetailObj = val;
    },
    closeAndRefresh() {
      this.editQuotaDialog = false;
      this.initList();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>